<template>
  <v-row>
    <v-col cols="12" md="12">
      <div class="box">
        <v-row>
          <v-col cols="12" md="3" class="py-0">
            <v-autocomplete
              label="Cliente / CPF / CNPJ"
              :items="customerList"
              v-model="operation.customer"
              item-value="id"
              :item-text="itemTextCustomers"
              return-object
              dense
              clearable
              outlined
              v-disabled-icon-focus
            >
            </v-autocomplete>
          </v-col>
          <v-col cols="12" md="3" class="py-0">
            <v-autocomplete
              label="Cidade"
              :items="exchangeStoreCities"
              v-model="operation.storeCity"
              dense
              clearable
              outlined
              v-disabled-icon-focus
            >
            </v-autocomplete>
          </v-col>
          <v-col cols="12" md="3" class="py-0">
            <v-autocomplete
              label="Banco"
              :items="bankList"
              v-model="operation.bank"
              item-value="id"
              :item-text="itemTextBanks"
              return-object
              dense
              clearable
              outlined
              v-disabled-icon-focus
            >
            </v-autocomplete>
          </v-col>
          <v-col cols="12" md="3" class="py-0">
            <v-autocomplete
              label="Loja"
              :items="storeList"
              v-model="operation.store"
              item-value="id"
              :item-text="itemTextStores"
              return-object
              dense
              clearable
              outlined
              v-disabled-icon-focus
            >
            </v-autocomplete>
          </v-col>
          <v-col cols="12" md="3" class="py-0">
            <v-autocomplete
              label="Natureza"
              :items="natureList"
              v-model="operation.nature"
              item-value="id"
              item-text="name"
              return-object
              dense
              clearable
              outlined
              v-disabled-icon-focus
            >
            </v-autocomplete>
          </v-col>
          <v-col cols="12" md="3" class="py-0">
            <v-autocomplete
              label="Forma de Entrega"
              :items="deliveryTypeList"
              v-model="operation.deliveryType"
              item-value="id"
              item-text="description"
              return-object
              dense
              clearable
              outlined
              v-disabled-icon-focus
            >
            </v-autocomplete>
          </v-col>
          <v-col cols="12" md="2" class="py-0">
            <v-autocomplete
              label="Moeda"
              :items="currencyList"
              v-model="operation.currency"
              item-value="id"
              :item-text="itemTextCurrencies"
              return-object
              dense
              clearable
              outlined
              v-disabled-icon-focus
            >
            </v-autocomplete>
          </v-col>
          <v-col cols="12" md="2" class="py-0">
            <MoneyField
              label="Spread"
              prefix=" "
              suffix=" %"
              v-model="operation.spread"
              persistentHint
              :hint="spreadHint"
              :min="operation.spreadMin"
              :max="operation.spreadMax"
            />
          </v-col>
          <v-col cols="12" md="2" class="py-0">
            <MoneyField
              label="Quantidade ME"
              prefix=" "
              v-model="operation.amount"
            />
          </v-col>
          <v-col cols="12" md="2" class="py-0">
            <MoneyField
              label="Taxa do Cliente"
              prefix="R$ "
              suffix=""
              :precision="6"
              v-model="operation.customerRate"
              persistentHint
              :hint="customerRateHint"
              :min="operation.customerRateMin"
              :max="operation.customerRateMax"
            />
          </v-col>
          <v-col v-show="operation.deliveryType.id == 2" cols="12" md="2" class="py-0">
            <MoneyField
              label="Custo de Entrega"
              prefix=" "
              v-model="operation.deliveryCost"
            />
          </v-col>
          <v-col cols="12" md="2" class="py-0">
            <Datepicker
              v-model="operation.deliveryDate"
              :label="operation.deliveryType.id == 1 ?'Data de Retirada' : 'Data de Entrega'"
              v-disabled-icon-focus
              :dataMinima="new Date().toISOString()"
            />
          </v-col>
          <v-col v-show="operation.deliveryType.id == 2" cols="12" md="2" class="py-0">
            <v-autocomplete
              label="Período"
              :items="deliveryTimeList"
              v-model="operation.deliveryTime"
              item-value="id"
              item-text="description"
              return-object
              dense
              clearable
              outlined
              v-disabled-icon-focus
            >
            </v-autocomplete>
          </v-col>
          <v-col cols="12" md="2" class="py-0">
            <Datepicker
              v-model="operation.travelDate"
              label="Data de Viagem"
              v-disabled-icon-focus
              :dataMinima="new Date().toISOString()"
            />
          </v-col>
          <v-col cols="12" md="2" class="py-0">
            <v-autocomplete
              label="Forma de Pagamento"
              :items="methodList"
              v-model="operation.paymentMethod"
              item-value="id"
              item-text="name"
              return-object
              dense
              clearable
              outlined
              v-disabled-icon-focus
            >
            </v-autocomplete>
          </v-col>
          <v-col class="py-0" cols="12" md="12">
            <v-textarea
              auto-grow
              outlined
              rows="1"
              v-model="operation.observation"
              label="Observações"
            ></v-textarea>
          </v-col>
          <v-col cols="12" md="3" class="py-0">
            <v-autocomplete
              label="Agente"
              :items="agentList"
              v-model="operation.agent"
              item-value="id"
              item-text="name"
              dense
              clearable
              outlined
              v-disabled-icon-focus
            >
            </v-autocomplete>
          </v-col>
        </v-row>
      </div>
    </v-col>
  </v-row>
</template>

<script>
// Components
import MoneyField from "@/components/comum/MoneyField";
import Datepicker from "@/components/comum/Datepicker.vue";

// Api
import apiCustomers from "@/api/customer/customer-api";
import apiDeliveryType from "@/api/exchangeStore/delivery-type-api";
import apiDeliveryTime from "@/api/exchangeStore/delivery-time-api";
import apiCorrespondent from "@/api/correspondent/correspondent-api";
import apiPaymentMethod from "@/api/configuration/payment-method-api";

// Models
import OperationModel from "@/model/operation-model";
import CustomerModel from "@/model/customer-model";
import CustomerExchangeBankModel from "@/model/customer-exchange-bank-model";
import DeliveryTypeModel from "@/model/delivery-type-model";
import DeliveryTimeModel from "@/model/delivery-time-model";
import CorrespondentModel from "@/model/correspondent-model";
import PaymentMethodModel from "@/model/payment-method-model";

// Mixins
import mixinValidationRules from "@/mixin/mixin-validation-rules";
import mixinAuthorization from "@/mixin/mixin-authorization";
import mixinMessage from "@/mixin/mixin-message";

// Utils
import moneyUtils from "@/utils/money";
import dateUtils from "@/utils/date";

export default {
  name: "OperationExchange",
  mixins: [ mixinValidationRules, mixinAuthorization, mixinMessage ],
  components: { MoneyField, Datepicker },
  data() {
    return {
      operation: this.operationProp,
      customerList: [],
      exchangeBankList: [],
      deliveryTypes: [],
      deliveryTimes: [],
      agentList: [],
      methodList: [],
    };
  },
  model: {
    prop: "operationProp",
    event: "onChange",
  },
  props: {
    operationProp: {
      type: Object,
    },
  },
  watch: {
    operation() {
      this.$emit("onChange", this.operation);
    },
    "operation.customer"() {
      if (this.operation.customer == undefined) {
        this.operation = new OperationModel({ exchangeType: this.operation.exchangeType, exchangeBuy: this.operation.exchangeBuy });
        return;
      }

      this.getExchangeBanks();
      this.operation.agent = this.operation.customer.correspondent;
    },
  },
  mounted() {
    this.getCustomersList();
    this.getDeliveryTypes();
    this.getDeliveryTimes();
    this.getagentList();
    this.getMethodList();
  },
  computed: {
    itemTextCustomers() {
      return (item) => !item.name ?  item.companyName + ' - ' + item.cpfCnpj : item.name + ' - ' + item.cpfCnpj;
    },
    exchangeStoreCities() {
      let flattened = this.exchangeBankList.map(i => i.bank.exchangeStores.map(j => j.city)).flat();

      return Array.from(new Set(flattened));
    },
    bankList() {
      return this.exchangeBankList.filter(i => i.bank.exchangeStores.filter(j => j.city == this.operation.storeCity).length).map(k => k.bank) || [];
    },
    itemTextBanks() {
      return (item) => { 
        let limit = this.exchangeBankList.filter(i => i.bank.id == item.id).map(j => j.limit).pop();
        let remainingLimit = limit?.remaining || 0;
        let formatedRemaining = item.useUsdLimit ? this.formatUSD(remainingLimit) : this.formatBRL(remainingLimit);
        let formatedExpire = dateUtils.maskDateIso(limit?.expireAt);

        return item.companyName + ' - ' + formatedRemaining + ' - ' + formatedExpire;
      }
    },
    storeList() {
      return this.bankList.filter(i => i.id == this.operation.bank.id).map(j => j.exchangeStores).flat().filter(k => k?.city == this.operation.storeCity) || [];
    },
    itemTextStores() {
      return (item) => item.name + ' - ' + item.cnpj;
    },
    natureList() {
      return this.exchangeBankList.filter(i => i.bank.id == this.operation.bank.id).map(j => j.operationNatures).flat() || [];
    },
    deliveryTypeList() {
      let type = this.storeList.flat().filter(i => i.id == this.operation.store.id).map(j => j.deliveryType).pop() || {};

      if (type.id == null) {
        return [];
      }

      if (type.id == 3) {
        return this.deliveryTypes.filter(i => i.id != 3);
      }

      return [type];
    },
    itemTextCurrencies() {
      return (item) => item.code + ' - ' + item.name;
    },
    currencyList() {
      return this.storeList.filter(i => i.id == this.operation.store.id).map(j => j.exchangeSpreads).flat().map(k => k.currency) || [];
    },
    deliveryTimeList() {
      let time = this.storeList.flat().filter(i => i.id == this.operation.store.id).map(j => j.deliveryTime).pop() || {};

      if (time.id == null) {
        return [];
      }

      if (time.id == 3 || time.id == 4) {
        return this.deliveryTimes.filter(i => i.id != 3 && i.id != 4);
      }

      return [time];
    },
    spreadHint() {
      if (this.operation.spreadMin && this.operation.spreadMax) {
        return `Min: ${this.operation.spreadMin}% - Max: ${this.operation.spreadMax}%`;
      }

      return '';
    },
    customerRateHint() {
      if (this.operation.customerRateMin && this.operation.customerRateMax) {
        return `Min: ${this.formatBRL(this.operation.customerRateMin, 6)} - Max: ${this.formatBRL(this.operation.customerRateMax, 6)}`;
      }

      return '';
    },
  },
  methods: {
    getCustomersList() {
      apiCustomers
        .findAll()
        .then((response) => {
          this.customerList = response.data.map((c) => new CustomerModel(c));
        })
        .catch((error) => {
          this.sendMessage(
            (error.response && error.response.data.mensagem) || error,
            "error"
          );
        });
    },

    getExchangeBanks() {
      apiCustomers
        .findExchangeBanks(this.operation.customer.id, this.operation.exchangeType.id)
        .then((response) => {
          this.exchangeBankList = response.data.map((c) => new CustomerExchangeBankModel(c));
        })
        .catch((error) => {
          this.sendMessage(
            (error.response && error.response.data.mensagem) || error,
            "error"
          );
        });
    },

    getDeliveryTypes() {
      if (this.deliveryTypes[0]) {
        return;
      }

      apiDeliveryType
        .findAll()
        .then((response) => {
          this.deliveryTypes = response.data.map(
            (t) => new DeliveryTypeModel(t)
          );
        })
        .catch((error) => {
          this.sendMessage(
            (error.response && error.response.data.mensagem) || error,
            "error"
          );
        });
    },

    getDeliveryTimes() {
      if (this.deliveryTimes[0]) {
        return;
      }

      apiDeliveryTime
        .findAll()
        .then((response) => {
          this.deliveryTimes = response.data.map(
            (t) => new DeliveryTimeModel(t)
          );
        })
        .catch((error) => {
          this.sendMessage(
            (error.response && error.response.data.mensagem) || error,
            "error"
          );
        });
    },

    getagentList() {
      apiCorrespondent
        .findAll()
        .then((response) => {
          this.agentList = response.data.map((c) => new CorrespondentModel(c));
        })
        .catch((error) => {
          this.sendMessage(
            (error.response && error.response.data.mensagem) || error,
            "error"
          );
        });
    },

    getMethodList() {
      apiPaymentMethod
        .findAll()
        .then((response) => {
          this.methodList = response.data.map((m) => new PaymentMethodModel(m));
        })
        .catch((error) => {
          this.sendMessage(
            (error.response && error.response.data.mensagem) || error,
            "error"
          );
        });
    },

    formatBRL(value, precision) {
      return moneyUtils.formatCurrency('BRL', value, precision);
    },
    formatUSD(value, precision) {
      return moneyUtils.formatCurrency('USD', value, precision);
    },

  }
};
</script>

<style></style>
