<template>
  <div>
    <v-snackbar v-model="snackbar" :vertical="false" :timeout="timeout" top="top" :color="type" elevation="0" right>
      {{ message }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar = false"> OK </v-btn>
      </template>
    </v-snackbar>
    <!-- expand on hover is bugged, needs mini-variant to work properly. https://github.com/vuetifyjs/vuetify/issues/14585 -->
    <v-navigation-drawer v-model="drawer" app :expand-on-hover="!isMobile" :mini-variant="!isMobile">
      <MenuStatic></MenuStatic>
    </v-navigation-drawer>
    <v-app-bar elevation="0" app>
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
      <img id="logotipo-barra-topo" class="pa-2" height="50px" src="../../assets/svg/logotype.svg" />
      <v-toolbar-title> </v-toolbar-title>
      <v-spacer></v-spacer>
      <span class="pr-3">
        {{ user.email }}
      </span>
      <v-divider vertical></v-divider>
      <!-- Darkmode, configurar esse cara assim que possível. -->
      <v-btn color="primary" icon id="mode-switcher" @click="changeTheme">
        <moon-icon v-if="$vuetify.theme.dark" size="1.5x" class="custom-class"></moon-icon>
        <sun-icon v-else size="1.5x" class="custom-class"></sun-icon>
      </v-btn>
      <v-menu offset-y>
        <template v-slot:activator="{ on: menu, attrs }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on: tooltip }">
              <v-btn color="primary" icon v-bind="attrs" v-on="{ ...tooltip, ...menu }"><settings-icon></settings-icon>
              </v-btn>
            </template>
            <span>Opções</span>
          </v-tooltip>
        </template>
        <v-list>
          <v-list-item-group>
            <v-list-item @click="changePassword()">
              <key-icon size="1.5x" class="mr-2"></key-icon>
              Alterar Senha
            </v-list-item>
            <v-list-item @click="logout()">
              <log-out-icon size="1.3x" class="mr-2"></log-out-icon>
              Sair
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>

    </v-app-bar>

    <v-dialog width="300px" v-model="changePasswordWindow">
      <v-card>
        <v-card-title>
          <h3>Alterar Senha</h3>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pa-5">
          <v-text-field label="Nova Senha" required :append-icon="value ? 'visibility_off' : 'visibility'"
            @click:append="() => (value = !value)" :type="value ? 'password' : 'text'" autofocus
            v-model="user.newPassword"></v-text-field>
          <v-text-field label="Confirme sua senha" required :append-icon="value ? 'visibility_off' : 'visibility'"
            @click:append="() => (value = !value)" :type="value ? 'password' : 'text'"
            v-model="user.confirmNewPassword"></v-text-field>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="updatePassword()" class="btn-primary" text>Salvar
          </v-btn>
          <v-btn @click="close()" class="btn-tertiary" text>Cancelar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
// Api
import apiUser from "@/api/user/user-api";

// Models
import UserModel from "@/model/user-model";

// Components
import MenuStatic from "@/components/menu/MenuStatic.vue";

// Utils
import utilsStorage from "@/utils/storage";

// Mixin
import mixinMessage from "@/mixin/mixin-message";
import mixinGeral from "@/mixin/mixin-geral";

// Tracking
import mixpanel from "mixpanel-browser";

export default {
  name: "Menu",
  mixins: [mixinMessage, mixinGeral],
  components: {
    MenuStatic,
  },
  data() {
    return {
      drawer: true,
      routes: [],
      changePasswordWindow: false,
      value: "password",
      user: new UserModel(),
      email: ""
    };
  },
  mounted() {
    let user = utilsStorage.getUserOnStorage()
    if (!user) return;

    this.user = new UserModel(user);

    this.startPendo();
  },
  computed: {
    isMobile() {
      return window.innerWidth <= '960';
    }
  },

  methods: {
    changeTheme() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      utilsStorage.setThemeSettings(this.$vuetify.theme.dark);
    },
    changePassword() {
      this.changePasswordWindow = true;
      mixpanel.track("click", {
        button_name: "btn_change_password",
      });
    },
    exit() {
      mixpanel.track("click", {
        button_name: "btn_logout",
      });
      this.logout();
    },

    logout() {
      apiUser
        .logout()
        .then(() => {
          utilsStorage.removeAuth();
          this.$router.push({ name: "Login" });
        })
        .catch((error) =>
          this.sendMessage(error.response.data.mensagem, "error")
        );
    },
    startPendo() {
      if (!window.pendo) return;
      window.pendo.initialize({
        visitor: {
          id: this.user.id,
          email: this.user.email,
          full_name: this.user.name,
          role: this.user.profileId,
        },
        account: {
          id: this.user.id,
        },
      });
    },
    updatePassword() {
      if (this.user.newPassword === this.user.confirmNewPassword) {
        apiUser
          .changePassword(this.user.newPassword)
          .then(() => {
            this.sendMessage("Senha alterada com sucesso", "success");
            this.changePasswordWindow = false;
            this.cleanFields();
          })
          .catch(() => {
            this.sendMessage("Não foi possível alterar a senha", "error");
          });
      } else {
        this.sendMessage("As senhas devem ser iguais", "error");
      }
    },

    close() {
      this.cleanFields();
      this.changePasswordWindow = false;
    },

    cleanFields() {
      this.user = new UserModel();
    },
  },
};
</script>

<style lang="scss" scoped>
.top-bar {
  border-radius: 10px !important;
  background-color: #fafafa;
}
</style>
