<template>
  <div class="ma-5">
    <v-snackbar v-model="snackbar" :vertical="false" :timeout="timeout" top="top" :color="type" elevation="0" right>
      {{ message }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar = false">OK</v-btn>
      </template>
    </v-snackbar>

    <!-- Skeleton Loader -->
    <v-row v-if="loading">
      <v-col cols="12" md="12">
        <v-skeleton-loader type="button" min-width="100px"></v-skeleton-loader>
        <v-skeleton-loader type="table-thead" min-width="100px"></v-skeleton-loader>
        <v-skeleton-loader type="table-tbody" min-width="100px"></v-skeleton-loader>
        <v-skeleton-loader type="table-tfoot" min-width="100px"></v-skeleton-loader>
      </v-col>
    </v-row>

    <v-row v-else>
      <v-col cols="12" md="12">
        <h1 class="titulo">Controle de clientes</h1>
        <v-divider></v-divider>
      </v-col>

      <v-col cols="12" md="9">
        <v-btn class="btn-primary ml-0" text @click="addCustomers">
          <plus-icon class="pr-1"></plus-icon>
          Adicionar</v-btn>
        <v-btn class="btn-secondary ml-2" text @click="openModalPreForm">
          <file-text-icon class="pr-1"></file-text-icon>
          Enviar pré-cadastro</v-btn>
      </v-col>

      <v-col cols="12" md="3">
        <v-text-field v-model="search" append-icon="mdi-magnify" label="Pesquisar" single-line
          hide-details></v-text-field>
      </v-col>

      <v-col cols="12" md="12">
        <v-card class="card-cambio">
          <v-data-table :headers="columns" :items="customers" :search="search" sort-by="id" sort-desc :items-per-page="10"
            single-expand :expanded.sync="expanded" item-key="id" show-expand>
            <template v-slot:[`item.id`]="{ item }">
              <td width="100px">
                {{ item.id }}
              </td>
            </template>
            <template v-slot:[`item.name`]="{ item }">
              <td>
                {{ item.name }}
              </td>
            </template>
            <template v-slot:[`item.createdAt`]="{ item }">
              {{ item.createdAt | date }}
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="primary" icon text @click="editCustomer(item)" v-bind="attrs" v-on="on">
                    <edit-2-icon size="1.3x" class="custom-class"></edit-2-icon>
                  </v-btn>
                </template>
                <span>Editar</span>
              </v-tooltip>
            </template>
            <template v-slot:expanded-item="{ headers }">
              <td :colspan="headers.length">
                <v-data-table :headers="expandedColumns" :items="expandedItem" disable-sort disable-pagination
                  disable-filtering hide-default-footer :items-per-page="-1">
                  <template v-slot:[`item.limitIn`]="{ item }">
                    <template v-if="item.limitIn">
                      {{ item.limitIn }}
                    </template>
                    <template v-else>
                      <corner-down-right-icon size="1.5x" class="hidden-sm-and-down"></corner-down-right-icon>
                      <arrow-down-icon size="1.5x" class="hidden-md-and-up"></arrow-down-icon>
                    </template>
                  </template>

                  <template v-slot:[`item.limitOut`]="{ item }">
                    <template v-if="item.limitOut">
                      {{ item.limitOut }}
                    </template>
                    <template v-else>
                      <corner-down-left-icon size="1.5x" class="hidden-sm-and-down"></corner-down-left-icon>
                      <arrow-up-icon size="1.5x" class="hidden-md-and-up"></arrow-up-icon>
                    </template>
                  </template>

                  <template v-slot:[`item.status`]="{ item }">
                    <v-chip small label :color="item.statusColor">
                      {{ item.status }}
                    </v-chip>
                  </template>

                  <template v-slot:[`item.actions`]="{ item }">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon text @click="() => { return }" v-bind="attrs" v-on="on"
                          :disabled="item.status != 'Aprovado'">
                          <v-icon large dense>mdi-bank-transfer</v-icon>
                        </v-btn>
                      </template>
                      <span>Operar</span>
                    </v-tooltip>
                  </template>
                </v-data-table>
              </td>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog persistent width="500" v-model="modalPreForm">
      <v-card>
        <v-card-title>
          <h3>Novo cliente</h3>
        </v-card-title>
        <v-divider class="mt-3 pb-3"> </v-divider>
        <v-card-text class="pa-5">
          <v-row>
            <v-col cols="12" md="12">
              <v-form validation v-model="preFormValid" ref="preForm">
                <v-row>
                  <v-col cols="12" md="12">
                    <v-radio-group v-model="customer.registerType.id">
                      <v-radio v-for="(item, i) in listOfRegisterTypes" :key="i" :value="item.id"
                        :label="item.description" :rules="[requiredField]"></v-radio>
                    </v-radio-group>
                  </v-col>
                  <v-col cols="12" md="12">
                    <v-text-field v-model="customer.name" label="Nome" autocomplete="off" type="text" dense
                      :rules="[requiredField]">
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="12">
                    <v-text-field v-model="customer.email" label="E-mail" autocomplete="off" type="email" dense
                      :rules="[requiredField, validationEmail]">
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="12">
                    <v-autocomplete label="Natureza da Operação" :items="operationNatureList" item-text="name"
                      item-value="id" v-model="customer.additional.operationNature" return-object clearable dense
                      v-disabled-icon-focus>
                    </v-autocomplete>
                  </v-col>
                </v-row>
              </v-form>
            </v-col>

            <!-- For now we arent sending these -->
            <!-- <v-col cols="12" md="12">
              <v-autocomplete
                label="Bancos"
                :items="listOfBanks"
                v-model="customer.banks"
                item-value="id"
                item-text="description"
                return-object
                multiple
                chips
                dense
                clearable
              ></v-autocomplete>
            </v-col> -->
            <v-col cols="12" md="12">
              <v-autocomplete label="Agente" :items="correspondentsList" v-model="customer.correspondent" item-value="id"
                item-text="name" return-object dense clearable></v-autocomplete>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text class="btn-tertiary ma-2" @click="closeModalPreForm">Cancelar</v-btn>
          <v-btn text class="btn-secondary ma-2" :disabled="!preFormValid" @click="sendPreForm">Enviar por email</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <confirmation-window v-model="openWindowConfirmation" :callbackPrimary="callbackConfirmDeletion"
      :message="deleteMessage" @onChange="clearInputs"></confirmation-window>
  </div>
</template>
<script>
// Apis
import apiCustomers from "@/api/customer/customer-api";
import apiOperationNature from "@/api/configuration/operation-nature-api";
import apiCorrespondent from "@/api/correspondent/correspondent-api";

// Models
import CustomerModel from "@/model/customer-model";
import OperationNatureModel from "@/model/operation-nature-model";
import CorrespondentModel from "@/model/correspondent-model";

// Mixins
import mixinMessage from "@/mixin/mixin-message";

// Components
import ConfirmationWindow from "@/components/comum/ConfirmationWindow";

// Utils
import dateUtils from "@/utils/date";
import utilsStorage from "@/utils/storage";

// Constants
import {
  PROFILES,
  REGISTER_TYPE,
  BANKS,
} from "@/constants/general-constants";

// Tracking
import mixpanel from "mixpanel-browser";

export default {
  name: "CustomersControl",
  mixins: [mixinMessage],
  components: {
    ConfirmationWindow,
  },
  filters: {
    date(value) {
      return dateUtils.maskDateIso(value);
    },
    dateHour(value) {
      return dateUtils.maskDateAndHourIso(value);
    },
    translateProfileDescription(value) {
      if (isNaN(value)) {
        return value;
      }

      return PROFILES.find((p) => p.id == value).description;
    },
  },
  data() {
    return {
      listOfRegisterTypes: Object.values(REGISTER_TYPE),
      correspondentsList: [],
      listOfBanks: BANKS,
      passwordVisible: "password",
      dialog: false,
      loading: true,
      updateMode: false,
      disableButtons: false,
      openWindowConfirmation: false,
      deleteMessage: "",
      search: "",
      modalPreForm: false,
      user: utilsStorage.getUserOnStorage(),
      customer: new CustomerModel(),
      customers: [],
      validationEmail: (v) =>
        !v ||
        /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
        "Informe um e-mail válido",
      callbackConfirmDeletion: () => { },
      columns: [
        {
          text: "Código",
          value: "id",
          align: "left",
          sortable: true,
        },
        { text: "Nome", value: "name", align: "left", sortable: true },
        {
          text: "Data de cadastro",
          value: "createdAt",
          align: "center",
          sortable: true,
        },
        { text: "", value: "actions", align: "right", sortable: false },
      ],
      preFormValid: false,
      requiredField: (v) => !!v || "Este campo é obrigatório",
      expanded: [],
      expandedItem: [
        { type: "Remessas", bankName: "Ourinvest", limitIn: "R$ 100000,00", limitUnified: null, limitOut: "R$ 100000,00", operationNatures: ["Disponibilidade", " Compra de Imóveis", " Aporte de Capital"], status: "Aprovado", statusColor: "#24b529" },
        { type: "Remessas", bankName: "Treviso", limitIn: null, limitUnified: "USD 20000,00", limitOut: null, operationNatures: ["Disponibilidade", " Aporte de Capital"], status: "Pendente", statusColor: "#ffff47" },
        { type: "Câmbio Turismo", bankName: "Confidence", limitIn: "", limitUnified: "R$ 60000,00", limitOut: "", operationNatures: ["Espécie/Papel Moeda"], status: "Recusado", statusColor: "#fc3f3f" },
      ],
      expandedColumns: [
        { text: "Tipo", value: "type", align: "left", width: "10%" },
        { text: "Banco Vinculado", value: "bankName", align: "left", width: "10%" },
        { text: "Limite Disponível IN - Receber", value: "limitIn", align: "center", width: "10%" },
        { text: "", value: "limitUnified", align: "center", width: "5%" },
        { text: "Limite Disponível OUT - Enviar", value: "limitOut", align: "center", width: "10%" },
        { text: "Naturezas Aprovadas", value: "operationNatures", align: "left", },
        { text: "Status", value: "status", align: "center", width: "10%" },
        { text: "", value: "actions", align: "center", width: "10%" },
      ],
      operationNatureList: [],
    };
  },
  mounted() {
    this.getCustomersList();
    mixpanel.track("page_view", {
      name_of_page_viewed: "users_control",
      user_id: this.user.id,
    });
    this.getOperationNatureList();
    this.getCorrespondentsList();
  },

  computed: {
    minimumDate() {
      return new Date().toISOString();
    },
  },

  methods: {
    getOperationNatureList() {
      apiOperationNature
        .findAll()
        .then((response) => {
          this.operationNatureList = response.data.map((n) => new OperationNatureModel(n));
        })
        .catch((error) => {
          this.sendMessage(
            (error.response && error.response.data.mensagem) || error,
            "error"
          );
        });
    },

    getCorrespondentsList() {
      apiCorrespondent
        .findAll()
        .then((response) => {
          this.correspondentsList = response.data.map((c) => new CorrespondentModel(c));
        })
        .catch((error) => {
          this.sendMessage(
            (error.response && error.response.data.mensagem) || error,
            "error"
          );
        });
    },

    getCustomersList() {
      apiCustomers
        .findAll()
        .then((response) => {
          this.customers = response.data.map((c) => new CustomerModel(c));
          this.clearInputs();
          this.loading = false;
        })
        .catch((error) => {
          this.sendMessage(
            (error.response && error.response.data.mensagem) || error,
            "error"
          );
          this.loading = false;
        });
    },

    addCustomers() {
      this.$router.push({
        name: "FormCreateCustomer",
      });
    },
    async openModalPreForm() {
      this.customer = new CustomerModel();
      this.$refs.preForm && (await this.$refs.preForm.reset());
      this.modalPreForm = true;
    },

    closeModalPreForm() {
      this.modalPreForm = false;
    },

    // TODO - Adicionar tracking
    editCustomer(customer) {
      mixpanel.track("click", {
        button_name: "edit_customer",
      });
      this.$router
        .push({
          name: "FormEditCustomer",
          params: {
            id: customer.id,
          },
        })
        .catch(() => { });
    },

    cancel() {
      this.dialog = false;
      this.customer = new CustomerModel();
      this.updateMode = false;
    },

    clearInputs() {
      this.customer = new CustomerModel();
    },

    deleteCustomerConfirmationDialog(customer) {
      // TODO Adicionar tracking
      this.customer = new CustomerModel(customer);
      this.deleteMessage = `Deseja realmente inativar o cliente &nbsp; <strong>${customer.name}</strong>?`;
      this.callbackConfirmDeletion = this.deleteCustomer;
      this.openWindowConfirmation = true;
    },

    updateListCustomer(customer, deleteFromList) {
      var index = this.customers
        .map((u) => parseInt(u.id))
        .indexOf(parseInt(customer.id));
      !deleteFromList
        ? this.customers.splice(index, 1, customer)
        : this.customers.splice(index, 1);
    },

    sendPreForm() {
      mixpanel.track("click", {
        name_of_page_viewed: "users_control",
        btn_name: "pre_register_form_save",
      });

      this.customer.sendExternalLink = true;
      apiCustomers
        .add(this.customer)
        .then((response) => {
          let customer = new CustomerModel(response);
          this.customers.unshift(customer);
          this.sendMessage("Cliente salvo com sucesso!", "success");
          this.closeModalPreForm();
        })
        .catch((error) => {
          this.sendMessage(
            (error.response && error.response.data.mensagem) || error,
            "error"
          );
        });
    },
  },
};
</script>

<style scoped>
.v-data-table::v-deep>.v-data-table__wrapper tbody tr.v-data-table__expanded__content {
  box-shadow: none;
}
</style>
