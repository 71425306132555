<template>
  <v-row>
    <v-snackbar v-model="snackbar" :vertical="false" :timeout="timeout" top="top" :color="type" elevation="0" right>
      {{ message }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar = false">OK</v-btn>
      </template>
    </v-snackbar>
    <v-col cols="12" md="">
      <div class="box">
        <OperationalDocuments v-model="operation" />
        <CustomerOperationalDocuments v-model="operation" />
      </div>
    </v-col>

  </v-row>
</template>

<script>
// Components
import CustomerOperationalDocuments from "./CustomerOperationalDocuments";
import OperationalDocuments from "./OperationalDocuments";

// Mixins
import mixinMessage from "@/mixin/mixin-message";

export default {
  name: "OperationDocuments",
  mixins: [ mixinMessage ],
  components: { CustomerOperationalDocuments, OperationalDocuments },
  model: {
    prop: "operationProp",
    event: "onChange",
  },
  props: {
    operationProp: {
      type: Object,
    },
  },
  data() {
    return {
      operation: this.operationProp,
    };
  },
  mounted() { },
  watch: {  },
  methods: {  },
};
</script>

<style scoped></style>
