import Login from '@/views/Login.vue';
import Dashboard from '@/views/dashboard/Dashboard';
import UsersControl from '@/views/users/UsersControl';
import CustomersControl from '@/views/customers/CustomersControl';
import FormCustomer from '@/views/customers/FormCustomer';
import ExchangeBankControl from '@/views/exchangeBank/ExchangeBankControl';
import FormBank from '@/views/exchangeBank/FormBank';
import CustomersAuthorizationControl from '@/views/customers/CustomersAuthorizationControl';
import FormAuthorizationCustomer from '@/views/customers/FormAuthorizationCustomer';
import FormCorrespondent from '@/views/correspondents/FormCorrespondent';
import CorrespondentsControl from '@/views/correspondents/CorrespondentsControl';
import OperationNaturesControl from '@/views/configurations/OperationNaturesControl';
import CurrenciesControl from '@/views/configurations/CurrenciesControl';
import PaymentMethodsControl from '@/views/configurations/PaymentMethodsControl';
import FormOperation from '@/views/operations/FormOperation';
import OpenOperationsReport from '@/views/reports/OpenOperationsReport';

const routes = [
  {
    path: "/login",
    name: "Login",
    component: Login,
    title: "Login",
    meta: { requireAuth: false }
  },
  {
    path: "/",
    name: "Dashboard",
    component: Dashboard,
    title: "Dashboard",
    meta: { requireAuth: true }
  },
  {
    path: "/users",
    name: "UsersControl",
    component: UsersControl,
    title: "Controle de Usuários",
    meta: { requireAuth: true }
  },
  {
    path: "/customers",
    name: "CustomersControl",
    component: CustomersControl,
    title: "Controle de Clientes",
    meta: { requireAuth: true }
  },
  {
    path: "/customers/create",
    name: "FormCreateCustomer",
    component: FormCustomer,
    title: "Criação e Edição de Clientes",
    meta: { requireAuth: true }
  },
  {
    path: "/customers/update/:id",
    name: "FormEditCustomer",
    component: FormCustomer,
    title: "Criação e Edição de Clientes",
    meta: { requireAuth: true },
  },
  {
    path: "/customers/external-acess/:token",
    name: "FormEditCustomerExternalAcess",
    component: FormCustomer,
    title: "Criação e Edição de Clientes",
    meta: { requireAuth: false },
  },
  {
    path: "/exchange-banks",
    name: "ExchangeBankControl",
    component: ExchangeBankControl,
    title: "Controle de Bancos",
    meta: { requireAuth: true },
  },
  {
    path: "/exchange-banks/create",
    name: "FormCreateBank",
    component: FormBank,
    title: "Criação e Edição de Bancos",
    meta: { requireAuth: true }
  },
  {
    path: "/exchange-banks/update/:id",
    name: "FormEditBank",
    component: FormBank,
    title: "Criação e Edição de Bancos",
    meta: { requireAuth: true },
  },
  {
    path: "/customers-authorizations",
    name: "CustomersAuthorizationControl",
    component: CustomersAuthorizationControl,
    title: "Autorização de Clientes",
    meta: { requireAuth: true },
  },
  {
    path: "/customers-authorizations/:id",
    name: "FormAuthorizationCustomer",
    component: FormAuthorizationCustomer,
    title: "Autorização de Clientes",
    meta: { requireAuth: true },
  },
  {
    path: "/correspondents",
    name: "CorrespondentsControl",
    component: CorrespondentsControl,
    title: "Controle de Correspondentes",
    meta: { requireAuth: true }
  },
  {
    path: "/correspondents/create",
    name: "FormCreateCorrespondent",
    component: FormCorrespondent,
    title: "Criação e Edição de Correspondentes",
    meta: { requireAuth: true }
  },
  {
    path: "/correspondents/update/:id",
    name: "FormEditCorrespondent",
    component: FormCorrespondent,
    title: "Criação e Edição de Correspondentes",
    meta: { requireAuth: true },
  },
  {
    path: "/correspondents/external-acess/:token",
    name: "FormEditCorrespondentExternalAcess",
    component: FormCorrespondent,
    title: "Criação e Edição de Correspondentes",
    meta: { requireAuth: false },
  },
  {
    path: "/configurations/operation-natures",
    name: "OperationNaturesControl",
    component: OperationNaturesControl,
    title: "Controle de Naturezas de Operação",
    meta: { requireAuth: true }
  },
  {
    path: "/configurations/currencies",
    name: "CurrenciesControl",
    component: CurrenciesControl,
    title: "Controle de Moedas",
    meta: { requireAuth: true }
  },
  {
    path: "/configurations/payment-methods",
    name: "PaymentMethodsControl",
    component: PaymentMethodsControl,
    title: "Controle de Métodos de Pagamento",
    meta: { requireAuth: true }
  },
  {
    path: "/operations",
    name: "FormOperation",
    component: FormOperation,
    title: "Cadastro de Operações",
    meta: { requireAuth: true }
  },
  {
    path: "/reports/operations/open",
    name: "OpenOperationsReport",
    component: OpenOperationsReport,
    title: "Relatório de Operações em Aberto",
    meta: { requireAuth: true }
  },
  {
    path: "/operations/:id",
    name: "FormEditOperation",
    component: FormOperation,
    title: "Editar Operação",
    meta: { requireAuth: true },
  },

];

export default routes;