import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify';
import VueMask from 'v-mask';
import money from 'v-money';
import 'material-design-icons-iconfont/dist/material-design-icons.css';
import '@/static/styles/_index.scss';
import mixpanel from 'mixpanel-browser'
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";
import utilsStorage from "@/utils/storage";

import {
  ActivityIcon,
  ArchiveIcon,
  CalendarIcon,
  UserPlusIcon,
  CheckSquareIcon,
  CheckCircleIcon,
  CheckIcon,
  ClockIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  CopyIcon,
  EditIcon,
  Edit2Icon,
  Edit3Icon,
  EyeIcon,
  FrownIcon,
  FilterIcon,
  FolderIcon,
  FileTextIcon,
  HeartIcon,
  InfoIcon,
  KeyIcon,
  LogOutIcon,
  MenuIcon,
  MoonIcon,
  NavigationIcon,
  PrinterIcon,
  SunIcon,
  SettingsIcon,
  SmileIcon,
  PlusIcon,
  PaperclipIcon,
  TrashIcon,
  UserIcon,
  UsersIcon,
  XCircleIcon,
  ZapIcon,
  DownloadIcon,
  CornerDownRightIcon,
  CornerDownLeftIcon,
  ArrowDownIcon,
  ArrowUpIcon,
} from 'vue-feather-icons';

Vue.component("activity-icon", ActivityIcon);
Vue.component("archive-icon", ArchiveIcon);
Vue.component("calendar-icon", CalendarIcon);
Vue.component("user-plus-icon", UserPlusIcon);
Vue.component("check-square-icon", CheckSquareIcon);
Vue.component("check-circle-icon", CheckCircleIcon);
Vue.component("check-icon", CheckIcon);
Vue.component("clock-icon", ClockIcon);
Vue.component("chevron-left-icon", ChevronLeftIcon);
Vue.component("chevron-right-icon", ChevronRightIcon);
Vue.component("copy-icon", CopyIcon);
Vue.component("edit-icon", EditIcon);
Vue.component("edit-2-icon", Edit2Icon);
Vue.component("edit-3-icon", Edit3Icon);
Vue.component("eye-icon", EyeIcon);
Vue.component("frown-icon", FrownIcon);
Vue.component("filter-icon", FilterIcon);
Vue.component("folder-icon", FolderIcon);
Vue.component("file-text-icon", FileTextIcon);
Vue.component("heart-icon", HeartIcon);
Vue.component("info-icon", InfoIcon);
Vue.component("key-icon", KeyIcon);
Vue.component("log-out-icon", LogOutIcon);
Vue.component("menu-icon", MenuIcon);
Vue.component("moon-icon", MoonIcon);
Vue.component("navigation-icon", NavigationIcon);
Vue.component("printer-icon", PrinterIcon);
Vue.component("paper-clip-icon", PaperclipIcon);
Vue.component("sun-icon", SunIcon);
Vue.component("settings-icon", SettingsIcon)
Vue.component("smile-icon", SmileIcon);
Vue.component("plus-icon", PlusIcon);
Vue.component("trash-icon", TrashIcon);
Vue.component("user-icon", UserIcon);
Vue.component("users-icon", UsersIcon);
Vue.component("x-circle-icon", XCircleIcon);
Vue.component("zap-icon", ZapIcon);
Vue.component("download-icon", DownloadIcon);
Vue.component("corner-down-right-icon", CornerDownRightIcon);
Vue.component("corner-down-left-icon", CornerDownLeftIcon);
Vue.component("arrow-down-icon", ArrowDownIcon);
Vue.component("arrow-up-icon", ArrowUpIcon);

Vue.config.productionTip = false

Vue.prototype.$eventBus = new Vue();

Vue.use(VueMask);
Vue.use(money, { precision: 2 });

Vue.use(mixpanel);

Vue.directive('disabledIconFocus', {
  bind(el) {
    disableIconFocus(el);
  },
  componentUpdated(el) {
    disableIconFocus(el);
  },
});

function disableIconFocus(el) {
  el.querySelectorAll('.v-input__icon button').forEach(x => x.setAttribute('tabindex', -1));
}

function getUserData() {
  let user = utilsStorage.getUserOnStorage()

  if (user) {
    Sentry.setUser(
      {
        email: user.mail,
        id: user.id,
        username: user.name
      }
    )
  }
}

getUserData()

Sentry.init({
  Vue,
  dsn: "https://d4ebd2a9995c468989176cdecfe71a3c@o1319164.ingest.sentry.io/4504126479597568",
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ["localhost", "hml-cambiohoje.deeprocket.com.br", /^\//], // TODO: Change this, when the project go to production
    }),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

mixpanel.init('c0b4fce0f9cc40059c199d04f7d35ba9', { debug: true })

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
