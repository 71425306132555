<template>
  <div class="ma-5">
    <v-snackbar v-model="snackbar" :vertical="false" :timeout="timeout" top="top" :color="type" elevation="0" right>
      {{ message }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar = false">OK</v-btn>
      </template>
    </v-snackbar>

    <v-row>
      <v-col cols="12" md="12">
        <h1>Autorização da Ficha</h1>
        <v-divider></v-divider>
      </v-col>
      <v-col cols="12" md="12">
        <FormCustomer isInAnalysis />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="12">
        <v-btn class="btn-tertiary float-right" text @click="cancel()">
          Cancelar
        </v-btn>
        <v-btn class="btn-primary mx-2 float-right" @click="registerPendencyModal = true" text>
          <x-circle-icon size="1.5x" class="custom-class pr-1"></x-circle-icon>
          Registrar Pendência
        </v-btn>
        <v-btn class="btn-primary mx-2 float-right" @click="approve()" text>
          <check-circle-icon size="1.5x" class="custom-class pr-1"></check-circle-icon>
          Aprovar
        </v-btn>
      </v-col>
    </v-row>

    <!-- Register Pendency Modal -->
    <v-dialog persistent scrollable eager min-width="40vw" max-width="100vh" v-model="registerPendencyModal">
      <v-card>
        <v-card-title>
          <h1 class="titulo-modal">Registrar Pendência</h1>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pt-5">
          <v-row>
            <v-col cols="12" md="12">
              <v-form id="register-pendency" ref="form">
                <v-row>
                  <v-col class="py-0" cols="12" md="12">
                    <v-textarea auto-grow outlined rows="3" v-model="statusUpdateEmail" label="Observações"></v-textarea>
                  </v-col>
                </v-row>
              </v-form>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-col class="text-right">
            <v-btn text class="btn-primary mr-2" @click="registerPendency()">
              Confirmar
            </v-btn>
            <v-btn text class="btn-tertiary" @click="registerPendencyModal = false">
              Cancelar
            </v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// Components
import FormCustomer from '@/views/customers/FormCustomer';

// Constants
import { REGISTER_STATUS } from "@/constants/general-constants.js";

// Apis
import apiCustomers from "@/api/customer/customer-api";

// Mixins
import mixinMessage from "@/mixin/mixin-message";
import mixinGeral from "@/mixin/mixin-geral";
import mixinNestedFormValidation from "@/mixin/mixin-nested-form-validation";

// Models
import RegisterStatusUpdateModel from "@/model/register-status-update-model";


// Tracking
import mixpanel from "mixpanel-browser";

export default {
  name: "FormAuthorizationCustomer",
  mixins: [mixinMessage, mixinGeral, mixinNestedFormValidation],
  components: {
    FormCustomer
  },
  data() {
    return {
      statusUpdateEmail: '',
      registerPendencyModal: false,
      registerStatus: REGISTER_STATUS,
      customerId: undefined,
    };
  },
  mounted() {
    this.customerId = this.$route.params.id;
  },
  computed: {

  },
  methods: {
    cancel() {
      mixpanel.track("click", {
        button_name: "cancel_authorization_form_customer",
      });

      this.$router.push({
        name: "CustomersAuthorizationControl",
      });
    },
    registerPendency() {
      mixpanel.track("click", {
        button_name: "register_pendency_form_customer",
      });

      let dto = new RegisterStatusUpdateModel({ statusId: this.registerStatus.PENDENCY.id, emailContent: this.statusUpdateEmail });

      this.updateCustomerStatus(dto);
    },
    approve() {
      mixpanel.track("click", {
        button_name: "aprove_form_customer",
      });

      let dto = new RegisterStatusUpdateModel({ statusId: this.registerStatus.APPROVED.id });

      this.updateCustomerStatus(dto);
    },
    updateCustomerStatus(dto) {
      apiCustomers
        .updateStatus(this.customerId, dto)
        .then(() => {
          this.sendMessage("Status atualizado com sucesso!", "success");

          this.$router.push({
            name: "CustomersAuthorizationControl",
          });
        })
        .catch((error) => {
          this.sendMessage(
            (error.response && error.response.data.mensagem) || error,
            "error"
          );
        });
    },
  },
};
</script>
