import CorrespondentModel from './correspondent-model';
import CurrencyModel from './currency-model';
import CustomerModel from './customer-model';
import DeliveryTimeModel from './delivery-time-model';
import DeliveryTypeModel from './delivery-type-model';
import ExchangeBankModel from './exchange-bank-model';
import ExchangeBankTypeModel from './exchange-bank-type-model';
import ExchangeStoreModel from './exchange-store-model';
import OperationNatureModel from './operation-nature-model';
import PaymentMethodModel from "./payment-method-model";
import LiquidationModel from "./liquidation-model";
import CustomerInternationalBankModel from './customer-international-bank-model';
import CustomerOperationalDocumentModel from './customer-operational-document-model';
import OperationDocumentModel from './operation-document-model';
import OperationStatusModel from './operation-status-model';

export default class OperationModel {
  constructor(obj) {
    obj = obj || {};

    let customerOperationalDocuments = obj.customerOperationalDocuments?.map(d => new CustomerOperationalDocumentModel(d));
    let documents = obj.documents?.map(d => new OperationDocumentModel(d));

    this.id = obj.id;
    this.exchangeType = new ExchangeBankTypeModel(obj.exchangeType);
    this.exchangeBuy = obj.exchangeBuy;
    this.customer = new CustomerModel(obj.customer);
    this.storeCity = obj.storeCity;
    this.bank = new ExchangeBankModel(obj.bank);
    this.store = new ExchangeStoreModel(obj.store);
    this.nature = new OperationNatureModel(obj.nature);
    this.deliveryType = new DeliveryTypeModel(obj.deliveryType);
    this.currency = new CurrencyModel(obj.currency);
    this.amount = obj.amount && parseFloat(obj.amount);
    this.currencyQuotation = obj.currencyQuotation;
    this.iof = obj.iof;
    this.iofValue = obj.iofValue;
    this.customerRateNoIOF = obj.customerRateNoIOF;
    this.customerRate = obj.customerRate;
    this.deliveryCost = obj.deliveryCost || 0;
    this.vet = obj.vet;
    this.totalValue = obj.totalValue;
    this.spread = obj.spread;
    this.deliveryDate = obj.deliveryDate;
    this.travelDate = obj.travelDate;
    this.deliveryTime = new DeliveryTimeModel(obj.deliveryTime);
    this.observation = obj.observation;
    this.agent = new CorrespondentModel(obj.agent);
    this.paymentMethod = new PaymentMethodModel(obj.paymentMethod);
    this.cep = obj.cep;
    this.state = obj.state;
    this.city = obj.city;
    this.neighborhood = obj.neighborhood;
    this.street = obj.street;
    this.number = obj.number;
    this.complement = obj.complement;
    this.receiverName = obj.receiverName;
    this.receiverDocument = obj.receiverDocument;
    this.receiverCellphone = obj.receiverCellphone;
    this.liquidation = new LiquidationModel(obj.liquidation);
    this.account = new CustomerInternationalBankModel(obj.account);
    this.exchangeContractCost = obj.exchangeContractCost;
    this.ir = obj.ir;
    this.irValue = obj.irValue;
    this.spreadMin = obj.spreadMin;
    this.spreadMax = obj.spreadMax;
    this.customerRateMin = obj.customerRateMin;
    this.customerRateMax = obj.customerRateMax;
    this.customerRateNoIOFMin = obj.customerRateNoIOFMin;
    this.customerRateNoIOFMax = obj.customerRateNoIOFMax;
    this.customerOperationalDocuments = customerOperationalDocuments?.length ? customerOperationalDocuments : [];
    this.documents = documents?.length ? documents : [];
    this.status = new OperationStatusModel(obj.status);
    this.bankRate = obj.bankRate;
    this.createdAt = obj.createdAt;
    this.updatedAt = obj.updatedAt;
  }
}