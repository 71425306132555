<template>
  <v-tooltip top>
    <template v-slot:activator="{ on, attrs }">
      <info-icon class="icon-tooltip" :size="iconSize" v-bind="attrs" v-on="on"></info-icon>
    </template>
    <span>{{ label }}</span>
  </v-tooltip>
</template>

<script>

export default {
  name: "InfoTooltip",
  props: {
    label: {
      type: String,
      required: true,
    },
    iconSize: {
      type: String,
      required: false,
      default: '1.6x'
    }
  },
};
</script>

<style lang="scss">

.icon-tooltip{
  cursor: pointer;
  color: $primary_color;
}
</style>
