<template>
  <div>
    <v-row v-for="(item, index) in customer.partnersShareholders" :key="index">
      <v-col class="py-0" cols="12" md="2">
        <v-checkbox
          label="Pessoa Jurídica?"
          v-model="item.isLegalPerson"
          dense
        ></v-checkbox>
      </v-col>
      <v-col class="py-0" cols="12" md="4">
        <v-text-field
          outlined
          dense
          :label="item.isLegalPerson ? 'Razão Social' : 'Nome'"
          v-model="item.name"
          :rules="[validationIsRequiredField]"
        ></v-text-field>
      </v-col>
      <v-col class="py-0" cols="12" md="3">
        <CpfOrCnpjField
          :isPJ="item.isLegalPerson"
          v-model="item.cpfCnpj"
          :rules="[validationIsRequiredField]"
        />
      </v-col>
      <v-col class="py-0" cols="12" md="2">
        <v-text-field
          outlined
          dense
          inputmode="decimal"
          max-lenght="3"
          label="% de participação"
          v-model="item.share"
          :rules="[validationIsRequiredField]"
        ></v-text-field>
      </v-col>
      <v-col
        class="py-0"
        cols="12"
        md="1"
        v-if="customer.partnersShareholders.length >= 2"
      >
        <v-btn icon class="float-right" @click="removeOtherPartners(index)" text
          ><trash-icon></trash-icon
        ></v-btn>
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <v-row class="py-0">
      <v-col class="pt-5">
        <v-btn
          class="btn-secondary float-right"
          @click="insertOtherPartners"
          text
          ><plus-icon></plus-icon> Adicionar outro</v-btn
        >
      </v-col>
    </v-row>
  </div>
</template>

<script>
// Components
import CpfOrCnpjField from "@/components/comum/CpfOrCnpjField.vue";

// Model
import PartnersShareholdersModel from "@/model/customer-partner-shareholder-model";

// Mixins
import mixinValidationRules from "@/mixin/mixin-validation-rules";

export default {
  name: "PartnersAndShareholders",
  mixins: [ mixinValidationRules ],
  components: { CpfOrCnpjField },
  model: {
    prop: "customerProp",
    event: "onChange",
  },
  props: {
    customerProp: {
      type: Object,
    },
  },
  watch: {
    customer() {
      this.$emit("onChange", this.customer);
    },
  },
  data() {
    return {
      customer: this.customerProp,
    };
  },
  methods: {
    insertOtherPartners() {
      this.customer.partnersShareholders.push(new PartnersShareholdersModel());
    },
    removeOtherPartners(index) {
      if (this.customer.partnersShareholders.length > 1) {
        this.customer.partnersShareholders.splice(index, 1);
      }
    },
  },
  mounted() {
    if (this.customer.partnersShareholders.length == 0) {
      this.customer.partnersShareholders = [new PartnersShareholdersModel()];
    }
  },
};
</script>

<style>
</style>