<template>
  <v-row>
    <v-col cols="12" md="">
      <div class="box">
        <h1>Documentos</h1>
        <v-row class="pt-3" :key="key">
          <v-col class="py-0" cols="12" md="12">
            <v-divider class="py-3"></v-divider>
          </v-col>
          <template v-for="(document, i) in documents">
            <v-col class="py-0" cols="12" md="3" :key="'type' + i">
              <v-autocomplete
                label="Tipo"
                :items="documentTypes"
                item-text="description"
                item-value="id"
                v-model="documents[i].operationDocumentType"
                return-object
                clearable
                dense
                outlined
                v-disabled-icon-focus
                :disabled="!!(documents[i].document || documents[i].path)"
              >
              </v-autocomplete>
            </v-col>
            <v-col class="py-0" cols="12" md="3" :key="'documents' + i">
              <DocumentInput
                :description="false"
                v-model="documents[i]"
                :api="apiDocument"
                :operationId="operation.id"
                :disabled="!documents[i].operationDocumentType?.id"
              >
                <template v-slot:append-outer>
                  <v-btn 
                    v-if="documents.length >= 2 && !(documents[i].document || documents[i].path)"
                    class="py-0"
                    icon
                    @click="removeDocument(i)"
                    text
                  >
                    <trash-icon></trash-icon>
                  </v-btn>
                  <v-btn class="py-0" icon @click="insertDocument" text>
                    <plus-icon></plus-icon>
                  </v-btn>
                </template>
              </DocumentInput>
            </v-col>
          </template>
        </v-row>
      </div>
    </v-col>
  </v-row>
</template>

<script>
// Components
import DocumentInput from "@/components/comum/DocumentInput";

// APIs
import apiOperationDocuments from "@/api/operation/operation-documents-api";

// Constants
import { OPERATION_DOCUMENT_TYPE } from "@/constants/general-constants.js";

// Models
import OperationDocumentModel from "@/model/operation-document-model";

export default {
  name: "OperationalDocuments",
  components: { DocumentInput },
  model: {
    prop: "operationProp",
    event: "onChange",
  },
  props: {
    operationProp: {
      type: Object,
    },
  },
  data() {
    return {
      operation: this.operationProp,
      apiDocument: apiOperationDocuments,
      documentTypes: OPERATION_DOCUMENT_TYPE,
      documents: [],
      key: 0,
    };
  },
  mounted() {
    this.documents = this.operation.documents;
    if (this.documents.length == 0) {
      this.documents = [new OperationDocumentModel()];
    }
  },
  watch: {
    "documents": {
      handler() {
        this.key = this.key + 1;
        this.updateDocuments();
      },
      deep: true,
    },
  },
  methods: {
    insertDocument() {
      this.documents.push(new OperationDocumentModel());
      this.key = this.key + 1;
    },
    removeDocument(index) {
      if (this.documents.length > 1) {
        this.documents.splice(index, 1);
      }
      this.key = this.key + 1;
    },
    updateDocuments() {
      let documents = this.documents.filter((d) => d.document);
      this.operation.documents = [ ...documents ];
      this.$emit("onChange", this.operation);
    },
  },
};
</script>

<style>

</style>