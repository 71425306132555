<template>
  <div class="ma-5">
    <v-snackbar v-model="snackbar" :vertical="false" :timeout="timeout" top="top" :color="type" elevation="0" right>
      {{ message }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar = false">OK</v-btn>
      </template>
    </v-snackbar>
    <v-row class="pb-2" v-if="!loading">
      <v-col cols="12" md="10">
        <v-row class="pb-2">
          <v-col cols="12" md="12">
            <v-card class="box card-cambio">
              <h1>Operação</h1>
              <v-divider></v-divider>
              <v-spacer class="py-2"></v-spacer>
              <v-radio-group v-model="operation.exchangeType.id" row>
                <v-radio v-for="(item, i) in filteredExchangeTypes" :key="i" :value="item.id"
                  :label="item.description"></v-radio>
              </v-radio-group>
              <v-radio-group v-model="operation.exchangeBuy" row>
                <v-radio :value="true" label="Compra"></v-radio>
                <v-radio :value="false" label="Venda"></v-radio>
              </v-radio-group>
            </v-card>
          </v-col>
          <v-col cols="12" md="12">
            <v-form ref="form" v-if="operation.exchangeType.id && operation.exchangeBuy != null">
              <div v-if="operation.exchangeType.id == 2">
                <OperationExchange v-model="operation" />
                <OperationExchangeDeliveryAddress v-if="operation.deliveryType.id == 2 && !operation.exchangeBuy"
                  v-model="operation" />
              </div>
              <div v-else>
                <OperationRemittance v-model="operation" />
                <OperationDocuments v-model="operation" />
              </div>
            </v-form>
          </v-col>
          <v-col cols="12" md="6">
            <v-btn class="btn-secondary mx-2 float-left" @click="copyCustomerMessageToClipboard" text>
              Copiar Mensagem do Cliente
            </v-btn>
          </v-col>
          <v-col cols="12" md="6">
            <v-btn class="btn-secondary mx-2 float-right" @click="createOpenOperation" text>
              Salvar Rascunho
            </v-btn>
            <v-btn class="btn-primary mx-2 float-right" @click="saveOperationModal = true" text>
              Salvar
            </v-btn>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="12" md="2">
        <OperationPreview v-model="operation" style="height: 100%;" />
      </v-col>

    </v-row>

    <!-- Save Operation Modal -->
    <v-dialog persistent max-width="600px" v-model="saveOperationModal">
      <v-card>
        <v-card-title>
          <h1 class="titulo-modal">Taxa do Banco</h1>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pt-5">
          <v-row>
            <v-col cols="12" md="12">
              <v-form id="add-operation" ref="form">
                <v-row>
                  <v-col class="py-0" cols="12" md="12">
                    <MoneyField
                      label="Taxa do Banco"
                      prefix="R$ "
                      v-model="operation.bankRate"
                      :precision="6"
                    />
                  </v-col>
                </v-row>
              </v-form>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
            <v-col cols="12" md="6" class="text-left px-0">
              <v-btn class="btn-secondary mx-2 float-left" @click="copyBankMessageToClipboard" text>
                Copiar Mensagem do Banco
              </v-btn>
            </v-col>
            <v-col cols="12" md="6" class="text-right">
              <v-btn text class="btn-primary mr-2" @click="createOperation">
                Salvar
              </v-btn>
              <v-btn text class="btn-tertiary" @click="saveOperationModal = false">
                Cancelar
              </v-btn>
            </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
// Components
import OperationExchange from "@/components/form-operation/OperationExchange.vue";
import OperationPreview from "@/components/form-operation/OperationPreview.vue";
import OperationExchangeDeliveryAddress from "@/components/form-operation/OperationExchangeDeliveryAddress.vue";
import OperationRemittance from "@/components/form-operation/OperationRemittance.vue";
import OperationDocuments from "@/components/form-operation/OperationDocuments.vue";
import MoneyField from "@/components/comum/MoneyField.vue";

// Apis
import apiExchangeBankType from "@/api/exchangeBank/exchange-bank-type-api";
import apiOperation from "@/api/operation/operation-api";
import apiOperationDocuments from "@/api/operation/operation-documents-api";

// Mixins
import mixinMessage from "@/mixin/mixin-message";

// Models
import OperationModel from "@/model/operation-model";
import ExchangeBankTypeModel from "@/model/exchange-bank-type-model";

// Tracking
import mixpanel from "mixpanel-browser";

// Utils
import operationMessagesTemplate from "@/utils/operationMessagesTemplate";

export default {
  name: "FormOperation",
  mixins: [
    mixinMessage,
  ],
  components: {
    OperationExchange,
    OperationPreview,
    OperationExchangeDeliveryAddress,
    OperationRemittance,
    OperationDocuments,
    MoneyField,
  },
  data() {
    return {
      operation: new OperationModel(),
      exchangeTypes: [],
      saveOperationModal: false,
      loading: false,
      editMode: false,
    };
  },
  mounted() {
    this.getExchangeTypes();
    if (this.$route.params.id) {
      this.loading = true;
      this.getOperationById(this.$route.params.id);
      this.editMode = true;
    } else {
      this.editMode = false;
    }
  },
  computed: {
    filteredExchangeTypes() {
      let filtered = this.exchangeTypes.filter(i => i.id != 3);
      return filtered;
    },
  },
  methods: {
    getExchangeTypes() {
      apiExchangeBankType
        .findAll()
        .then((response) => {
          this.exchangeTypes = response.data.map(t => new ExchangeBankTypeModel(t));
        })
        .catch((error) => {
          this.sendMessage(
            (error.response && error.response.data.mensagem) || error,
            "error"
          );
        });
    },

    getOperationById(id) {
      apiOperation
        .findId(id)
        .then((response) => {
          this.operation = new OperationModel(response.data);
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.sendMessage(
            (error.response && error.response.data.mensagem) || error,
            "error"
          );
          this.loading = false;
        });
    },

    createOperation() {
      mixpanel.track("click", {
        button_name: "create_operation",
      });

      this.operation.status.id = 2;

      this.saveOperation();
    },

    createOpenOperation() {
      mixpanel.track("click", {
        button_name: "create_open_operation",
      });

      this.operation.status.id = 1;

      this.copyCustomerMessageToClipboard();

      this.saveOperation();
    },


    saveOperation() {
      let operationDocuments = this.operation.documents;

      apiOperation
        .add(this.operation)
        .then((response) => {
          this.sendMessage("Operação salva com sucesso!", "success");

          this.saveDocuments(operationDocuments, response.data);

          this.$router.push({
            // TODO placeholder until reports page
            name: "CustomersControl",
          });
        })
        .catch((error) => {
          console.log(error);
          this.sendMessage(
            (error.response && error.response.data.mensagem) || error,
            "error"
          );
        });
    },

    saveDocuments(operationDocuments, operation) {
      operationDocuments?.forEach((document) => {
        let formData = new FormData();

        formData.append("operationId", operation.id);
        formData.append("idOperationDocumentType", document.operationDocumentType?.id);
        formData.append("document", document.document);

        apiOperationDocuments.add(formData);
      });
    },

    copyCustomerMessageToClipboard() {
      navigator.clipboard.writeText(operationMessagesTemplate.getCustomerMessage(this.operation));

      this.sendMessage(
        'A mensagem do cliente foi copiada em seu CTRL + C',
        "success"
      );
    },

    copyBankMessageToClipboard() {
      navigator.clipboard.writeText(operationMessagesTemplate.getBankMessage(this.operation));

      this.sendMessage(
        'A mensagem do banco foi copiada em seu CTRL + C',
        "success"
      );
    },
  },
};
</script>
