<template>
  <v-row>
    <v-snackbar v-model="snackbar" :vertical="false" :timeout="timeout" top="top" :color="type" elevation="0" right>
      {{ message }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar = false">OK</v-btn>
      </template>
    </v-snackbar>
    <v-col cols="12" md="12">
      <div class="box">
        <h1>Spreads de Remessa</h1>
        <v-divider class="py-3 mt-3"></v-divider>
        <v-row>
          <v-col cols="12" md="10">
            <v-btn class="btn-primary ml-0" text @click="openAddSpreadModal">
              <plus-icon class="pr-1"></plus-icon>
              Adicionar
            </v-btn>
          </v-col>

          <v-col cols="12" md="2">
            <v-text-field v-model="search" append-icon="mdi-magnify" label="Pesquisar" single-line
              hide-details></v-text-field>
          </v-col>

          <v-col cols="12" md="12">
            <v-card class="card-cambio">
              <v-data-table :headers="columns" :items="customer.spreads" :search="search" sort-by="id" sort-desc
                :items-per-page="10">
                <template v-slot:[`item.actions`]="{ item }">
                  <td class="buttons-column">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn class="btn-primary" text @click="editSpreadModal(item)" v-bind="attrs" v-on="on">
                          <edit-2-icon size="1.3x" class="custom-class pr-1"></edit-2-icon>
                          Editar
                        </v-btn>
                      </template>
                      <span>Editar</span>
                    </v-tooltip>
                  </td>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </v-col>

    <!-- Add Spread Modal -->
    <v-dialog persistent scrollable eager min-width="40vw" max-width="100vh" v-model="addSpreadModal">
      <v-card>
        <v-card-title>
          <h1 class="titulo-modal">Spread</h1>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pt-5">
          <v-row>
            <v-col cols="12" md="12">
              <v-form id="add-spread" ref="form">
                <v-row>
                  <v-col class="py-0" cols="12" md="12">
                    <v-autocomplete label="Moeda" :items="notUsedCurrencies" item-text="name" item-value="id"
                      :disabled="update" v-model="spread.currency" return-object clearable dense outlined
                      v-disabled-icon-focus>
                    </v-autocomplete>
                  </v-col>

                  <v-col class="py-0" cols="12" md="4">
                    <NumberField v-model="spread.minimumSpreadSell" outlined dense label="Spread Mínimo Venda(%)"
                      suffix="%"></NumberField>
                  </v-col>
                  <v-col class="py-0" cols="12" md="4">
                    <NumberField v-model="spread.recommendedSpreadSell" outlined dense label="Spread Recomendado Venda(%)"
                      suffix="%"></NumberField>
                  </v-col>
                  <v-col class="py-0" cols="12" md="4">
                    <NumberField v-model="spread.maximumSpreadSell" outlined dense label="Spread Máximo Venda(%)"
                      suffix="%"></NumberField>
                  </v-col>

                  <v-col class="py-0" cols="12" md="4">
                    <NumberField v-model="spread.minimumSpreadBuy" outlined dense label="Spread Mínimo Compra(%)"
                      suffix="%"></NumberField>
                  </v-col>
                  <v-col class="py-0" cols="12" md="4">
                    <NumberField v-model="spread.recommendedSpreadBuy" outlined dense label="Spread Recomendado Compra(%)"
                      suffix="%"></NumberField>
                  </v-col>
                  <v-col class="py-0" cols="12" md="4">
                    <NumberField v-model="spread.maximumSpreadBuy" outlined dense label="Spread Máximo Compra(%)"
                      suffix="%"></NumberField>
                  </v-col>

                </v-row>
              </v-form>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-col class="text-right">
            <v-btn v-if="!update" text class="btn-primary mr-2" @click="addSpread">
              Salvar
            </v-btn>
            <v-btn v-else-if="update" text class="btn-primary mr-2" @click="updateSpread">
              Salvar
            </v-btn>
            <v-btn text class="btn-tertiary" @click="addSpreadModal = false">
              Cancelar
            </v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
// Api
import apiCurrency from "@/api/spread/currency-api";

// Components
import NumberField from "@/components/comum/NumberField";

// Models
import CustomerSpreadModel from "@/model/customer-spread-model";
import CurrencyModel from "@/model/currency-model";

// Mixins
import mixinMessage from "@/mixin/mixin-message";

export default {
  name: "CustomerSpreads",
  mixins: [mixinMessage],
  components: {
    NumberField,
  },
  model: {
    prop: "customerProp",
    event: "onChange",
  },
  props: {
    customerProp: {
      type: Object,
    },
  },
  data() {
    return {
      customer: this.customerProp,
      search: "",
      currencies: [],
      columns: [
        { text: "Código", value: "currency.code", align: "left", sortable: true },
        { text: "Nome", value: "currency.name", align: "left", sortable: true },
        { text: "Spread Mínimo Venda(%)", value: "minimumSpreadSell", align: "right", sortable: true },
        { text: "Spread Recomendado Venda(%)", value: "recommendedSpreadSell", align: "right", sortable: true },
        { text: "Spread Máximo Venda(%)", value: "maximumSpreadSell", align: "right", sortable: true },
        { text: "Spread Mínimo Compra(%)", value: "minimumSpreadBuy", align: "right", sortable: true },
        { text: "Spread Recomendado Compra(%)", value: "recommendedSpreadBuy", align: "right", sortable: true },
        { text: "Spread Máximo Compra(%)", value: "maximumSpreadBuy", align: "right", sortable: true },
        { text: "", value: "actions", align: "right", sortable: false },
      ],
      spread: new CustomerSpreadModel(),
      addSpreadModal: false,
      update: false,
    };
  },
  mounted() {
    this.getCurrencies();
  },
  computed: {
    notUsedCurrencies() {
      let filtered = this.currencies.filter(currency => {
        return this.customer.spreads.filter(spread => {
          return spread.currency.id == currency.id;
        }).length == 0
      });

      if (this.spread?.currency?.id) {
        filtered.push(this.spread.currency)
      }

      return filtered;
    },
  },
  methods: {
    getCurrencies() {
      if (this.currencies[0]) {
        return;
      }

      apiCurrency.findAll()
        .then((response) => {
          this.currencies = response.data.map(
            (c) => new CurrencyModel(c)
          );
        })
        .catch((error) => {
          this.sendMessage(
            (error.response && error.response.data.mensagem) || error,
            "error"
          );
        })
    },

    openAddSpreadModal() {
      this.update = false;
      this.spread = new CustomerSpreadModel();
      this.addSpreadModal = true;
    },

    editSpreadModal(spread) {
      this.update = true;
      this.spread = Object.assign({}, spread);
      this.addSpreadModal = true;
    },

    addSpread() {
      this.customer.spreads.push(new CustomerSpreadModel(this.spread));
      this.addSpreadModal = false;
    },

    updateSpread() {
      let index = this.customer.spreads.findIndex((s) => s.id == this.spread.id);
      this.customer.spreads.splice(
        index,
        1,
        new CustomerSpreadModel(this.spread)
      );
      this.addSpreadModal = false;
    },
  }
};
</script>

<style></style>
