<template>
  <v-row>
    <v-col cols="12" md="12">
      <div class="box">
        <v-row>
          <v-col cols="12" md="3" class="py-0">
            <v-autocomplete
              label="Cliente / CPF / CNPJ"
              :items="customerList"
              v-model="operation.customer"
              item-value="id"
              :item-text="itemTextCustomers"
              return-object
              dense
              clearable
              outlined
              v-disabled-icon-focus
            >
            </v-autocomplete>
          </v-col>
          <v-col cols="12" md="3" class="py-0">
            <v-autocomplete
              label="Banco"
              :items="bankList"
              v-model="operation.bank"
              item-value="id"
              :item-text="itemTextBanks"
              return-object
              dense
              clearable
              outlined
              v-disabled-icon-focus
            >
            </v-autocomplete>
          </v-col>
          <v-col cols="12" md="3" class="py-0">
            <v-autocomplete
              label="Natureza"
              :items="natureList"
              v-model="operation.nature"
              item-value="id"
              item-text="name"
              return-object
              dense
              clearable
              outlined
              v-disabled-icon-focus
            >
            </v-autocomplete>
          </v-col>
          <v-col cols="12" md="3" class="py-0">
            <v-autocomplete
              label="Moeda"
              :items="currencies"
              v-model="operation.currency"
              item-value="id"
              :item-text="itemTextCurrencies"
              return-object
              dense
              clearable
              outlined
              v-disabled-icon-focus
            >
            </v-autocomplete>
          </v-col>
          <v-col cols="12" md="2" class="py-0">
            <MoneyField
              label="Spread"
              prefix=" "
              suffix=" %"
              v-model="operation.spread"
              persistentHint
              :hint="spreadHint"
              :min="operation.spreadMin"
              :max="operation.spreadMax"
            />
          </v-col>
          <v-col cols="12" md="2" class="py-0">
            <v-autocomplete
              label="Liquidação"
              :items="liquidationList"
              v-model="operation.liquidation"
              item-value="id"
              item-text="description"
              return-object
              dense
              clearable
              outlined
              v-disabled-icon-focus
            >
            </v-autocomplete>
          </v-col>
          <v-col cols="12" md="2" class="py-0">
            <v-autocomplete
              label="Conta"
              :items="operation.customer.internationalBanks"
              v-model="operation.account"
              item-value="id"
              :item-text="itemTextAccount"
              return-object
              dense
              clearable
              outlined
              v-disabled-icon-focus
            >
            </v-autocomplete>
          </v-col>
          <v-col cols="12" md="2" class="py-0">
            <MoneyField
              label="Quantidade ME"
              prefix=" "
              v-model="operation.amount"
            />
          </v-col>
          <v-col cols="12" md="2" class="py-0">
            <MoneyField
              label="Taxa do Cliente"
              prefix="R$ "
              suffix=""
              :precision="6"
              v-model="operation.customerRate"
              persistentHint
              :hint="customerRateHint"
              :min="operation.customerRateMin"
              :max="operation.customerRateMax"
            />
          </v-col>
          <v-col cols="12" md="2" class="py-0">
            <v-autocomplete
              label="Agente"
              :items="agentList"
              v-model="operation.agent"
              item-value="id"
              item-text="name"
              dense
              clearable
              outlined
              v-disabled-icon-focus
            >
            </v-autocomplete>
          </v-col>
          <v-col class="py-0" cols="12" md="12">
            <v-textarea
              auto-grow
              outlined
              rows="1"
              v-model="operation.observation"
              label="Observações"
            ></v-textarea>
          </v-col>
        </v-row>
      </div>
    </v-col>
  </v-row>
</template>

<script>
// Components
import MoneyField from "@/components/comum/MoneyField";

// Api
import apiCustomers from "@/api/customer/customer-api";
import apiCorrespondent from "@/api/correspondent/correspondent-api";
import apiCurrency from "@/api/spread/currency-api";

// Models
import OperationModel from "@/model/operation-model";
import CustomerModel from "@/model/customer-model";
import CustomerExchangeBankModel from "@/model/customer-exchange-bank-model";
import CorrespondentModel from "@/model/correspondent-model";
import CurrencyModel from "@/model/currency-model";

// Mixins
import mixinValidationRules from "@/mixin/mixin-validation-rules";
import mixinAuthorization from "@/mixin/mixin-authorization";
import mixinMessage from "@/mixin/mixin-message";

// Constants
import { LIQUIDATION } from "@/constants/general-constants";

// Utils
import moneyUtils from "@/utils/money";
import dateUtils from "@/utils/date";

export default {
  name: "OperationRemittance",
  mixins: [ mixinValidationRules, mixinAuthorization, mixinMessage ],
  components: { MoneyField, },
  data() {
    return {
      operation: this.operationProp,
      customerList: [],
      exchangeBankList: [],
      deliveryTypes: [],
      deliveryTimes: [],
      agentList: [],
      methodList: [],
      currencies: [],
      liquidationList: LIQUIDATION,
      customer: new CustomerModel(),
    };
  },
  model: {
    prop: "operationProp",
    event: "onChange",
  },
  props: {
    operationProp: {
      type: Object,
    },
  },
  watch: {
    operation() {
      this.$emit("onChange", this.operation);
    },
    "operation.customer"() {
      if (this.operation.customer == undefined) {
        this.operation = new OperationModel({ exchangeType: this.operation.exchangeType, exchangeBuy: this.operation.exchangeBuy });
        this.customer = new CustomerModel();
        return;
      }

      /* This prevents the constant api request of the same customer */
      if (this.operation.customer.id != undefined && this.operation.customer.id == this.customer.id) {
        return;
      }

      this.getExchangeBanks();
      this.getAgent();
      this.getCustomer();
    },
  },
  mounted() {
    this.getCustomersList();
    this.getagentList();
    this.getCurrencies();
  },
  computed: {
    itemTextCustomers() {
      return (item) => !item.name ?  item.companyName + ' - ' + item.cpfCnpj : item.name + ' - ' + item.cpfCnpj;
    },
    bankList() {
      return this.exchangeBankList.map(i => i.bank) || [];
    },
    itemTextBanks() {
      return (item) => {
        let limit = this.exchangeBankList.filter(i => i.bank.id == item.id).map(j => j.limit).pop();
        let remainingLimit = limit?.remaining || 0;
        let formatedRemaining = item.useUsdLimit ? this.formatUSD(remainingLimit) : this.formatBRL(remainingLimit);
        let formatedExpire = dateUtils.maskDateIso(limit?.expireAt);

        return item.companyName + ' - ' + formatedRemaining + ' - ' + formatedExpire;
      }
    },
    itemTextStores() {
      return (item) => item.name + ' - ' + item.cnpj;
    },
    natureList() {
      return this.exchangeBankList.filter(i => i.bank.id == this.operation.bank.id).map(j => j.operationNatures).flat() || [];
    },
    itemTextCurrencies() {
      return (item) => item.code + ' - ' + item.name;
    },
    itemTextAccount() {
      return (item) => item.accountName + ' - Banco: ' + item.bank + ' - IBAN: ' + item.numberIBAN +  ' - Código Swift/BIC: ' + item.swiftCodeBIC +  ' - Número ABA/Routing: ' + item.tabNumberRouting;
    },
    spreadHint() {
      if (this.operation.spreadMin && this.operation.spreadMax) {
        return `Min: ${this.operation.spreadMin}% - Max: ${this.operation.spreadMax}%`;
      }

      return '';
    },
    customerRateHint() {
      if (this.operation.customerRateMin && this.operation.customerRateMax) {
        return `Min: ${this.formatBRL(this.operation.customerRateMin, 6)} - Max: ${this.formatBRL(this.operation.customerRateMax, 6)}`;
      }

      return '';
    },
  },
  methods: {
    getCustomersList() {
      apiCustomers
        .findAll()
        .then((response) => {
          this.customerList = response.data.map((c) => new CustomerModel(c));
        })
        .catch((error) => {
          this.sendMessage(
            (error.response && error.response.data.mensagem) || error,
            "error"
          );
        });
    },

    getCustomer() {
      apiCustomers
        .findId(this.operation.customer.id)
        .then((response) => {
          this.operation.customer = new CustomerModel(response.data);
          this.customer = new CustomerModel(response.data);
        })
        .catch((error) => {
          this.sendMessage(
            (error.response && error.response.data.mensagem) || error,
            "error"
          );
        });
    },

    getExchangeBanks() {
      apiCustomers
        .findExchangeBanks(this.operation.customer.id, this.operation.exchangeType.id)
        .then((response) => {
          this.exchangeBankList = response.data.map((c) => new CustomerExchangeBankModel(c));
        })
        .catch((error) => {
          this.sendMessage(
            (error.response && error.response.data.mensagem) || error,
            "error"
          );
        });
    },

    getagentList() {
      apiCorrespondent
        .findAll()
        .then((response) => {
          this.agentList = response.data.map((c) => new CorrespondentModel(c));
        })
        .catch((error) => {
          this.sendMessage(
            (error.response && error.response.data.mensagem) || error,
            "error"
          );
        });
    },

    getCurrencies() {
      if (this.currencies[0]) {
        return;
      }

      apiCurrency.findAll()
        .then((response) => {
          this.currencies = response.data.map(
            (c) => new CurrencyModel(c)
          );
        })
        .catch((error) => {
          this.sendMessage(
            (error.response && error.response.data.mensagem) || error,
            "error"
          );
        })
    },

    getAgent() {
      let id = this.operation.customer.correspondent.id;
      apiCorrespondent
        .findId(id)
        .then((response) => {
          this.operation.agent = new CorrespondentModel(response.data);
        })
        .catch((error) => {
          this.sendMessage(
            (error.response && error.response.data.mensagem) || error,
            "error"
          );
        });
    },

    formatBRL(value, precision) {
      return moneyUtils.formatCurrency('BRL', value, precision);
    },
    formatUSD(value, precision) {
      return moneyUtils.formatCurrency('USD', value, precision);
    },


  }
};
</script>

<style></style>
