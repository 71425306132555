<template>
  <v-row>
    <v-snackbar v-model="snackbar" :vertical="false" :timeout="timeout" top="top" :color="type" elevation="0" right>
      {{ message }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar = false">OK</v-btn>
      </template>
    </v-snackbar>
    <v-col cols="12" md="12">
      <div class="box">
        <h1>Indicados</h1>
        <v-divider class="py-3 mt-3"></v-divider>
        <v-row>
          <v-col cols="12" md="10">
            <v-btn class="btn-primary ml-0" text @click="openAddIndicatedModal">
              <plus-icon class="pr-1"></plus-icon>
              Adicionar
            </v-btn>
          </v-col>
        
          <v-col cols="12" md="2">
            <v-text-field v-model="search" append-icon="mdi-magnify" label="Pesquisar" single-line hide-details></v-text-field>
          </v-col>
        
          <v-col cols="12" md="12">
            <v-card class="card-cambio">
              <v-data-table :headers="columns" :items="indicatedCorrespondents" :search="search" sort-by="id" sort-desc :items-per-page="10">
                <template v-slot:[`item.actions`]="{ item }">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn class="btn-primary" text @click="editIndicatedModal(item)" v-bind="attrs" v-on="on">
                        <edit-2-icon size="1.3x" class="custom-class pr-1"></edit-2-icon>
                        Editar
                      </v-btn>
                    </template>
                    <span>Editar</span>
                  </v-tooltip>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </v-col>

    <!-- Add Indicated Modal -->
    <v-dialog persistent scrollable eager min-width="80vw" max-width="80vw" v-model="addIndicatedModal">
      <v-card>
        <v-card-title>
          <h1 class="titulo-modal">Indicado</h1>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pt-5">
          <v-row>
            <v-col cols="12" md="12">
              <v-form id="add-indicated" ref="form">
                <v-row>
                  <v-col class="py-0" cols="12" md="12">
                    <FormCorrespondent
                      isIndicated
                      :indicatorId="correspondent.id"
                      :isUpdate="update"
                      :updateId="updateId"
                      :aferSaveCallback="updateIndicatedListAndCloseModal"
                      :cancelCallback="closeIndicatedModal"
                      :key="modalKey"
                    />
                  </v-col>
                </v-row>
              </v-form>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
// Apis
import apiCorrespondent from "@/api/correspondent/correspondent-api";

// Models
import CorrespondentModel from "@/model/correspondent-model";

// Mixins
import mixinMessage from "@/mixin/mixin-message";

export default {
  name: "CorrespondentIndicated",
  mixins: [mixinMessage],
  components: {
    /* https://v2.vuejs.org/v2/guide/components-edge-cases.html#Circular-References-Between-Components */
    FormCorrespondent: () => import ('../../views/correspondents/FormCorrespondent.vue')
  },
  model: {
    prop: "correspondentProp",
    event: "onChange",
  },
  props: {
    correspondentProp: {
      type: Object,
    },
  },
  data() {
    return {
      correspondent: this.correspondentProp,
      search: "",
      columns: [
        {
          text: "Código",
          value: "id",
          align: "left",
          sortable: true,
        },
        { text: "Nome", value: "name", align: "left", sortable: true },
        { text: "", value: "actions", align: "right", sortable: false },
      ],
      addIndicatedModal: false,
      update: false,
      indicatedCorrespondents: [],
      updateId: null,
      modalKey: 0,
    };
  },
  mounted() {
    this.getIndicatedCorrespondensList();
  },
  computed: {
    
  },
  methods: {
    getIndicatedCorrespondensList(){
      apiCorrespondent
        .findIndicated(this.correspondent.id)
        .then((response) => {
          this.indicatedCorrespondents = response.data.map((i) => new CorrespondentModel(i));
        })
        .catch((error) => {
          this.sendMessage(
            (error.response && error.response.data.mensagem) || error,
            "error"
          );
        });
    },

    closeIndicatedModal() {
      this.addIndicatedModal = false;
    },
    
    updateIndicatedListAndCloseModal(indicated) {
      let index = this.indicatedCorrespondents.findIndex((i) => i.id == indicated.id);

      if (index == -1) {
        this.indicatedCorrespondents.push(indicated);
      } else {
        this.indicatedCorrespondents.splice(
          index,
          1,
          indicated
        );
      }

      this.addIndicatedModal = false;
    },

    openAddIndicatedModal() {
      this.modalKey++;
      this.update = false;
      this.updateId = null;
      this.addIndicatedModal = true;
    },

    editIndicatedModal(indicated) {
      this.modalKey++;
      this.update = true;
      this.updateId = indicated.id;
      this.addIndicatedModal = true;
    },
  }
};
</script>

<style></style>
