module.exports = class OperationNatureModel {
  constructor(obj) {
    obj = obj || {};
    this.id = obj.id;
    this.name = obj.name;
    this.iof = (obj.iof && parseFloat(obj.iof)) || 0;
    this.ir = (obj.ir && parseFloat(obj.ir)) || 0;
    this.iofBuy = (obj.iofBuy && parseFloat(obj.iofBuy)) || 0;
    this.irBuy = (obj.irBuy && parseFloat(obj.irBuy)) || 0;
    this.createdAt = obj.createdAt;
    this.updatedAt = obj.updatedAt;
  }
}