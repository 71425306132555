<template>
  <v-container>
    <div>
      <v-row>
        <h1>Dashboard em desenvolvimento</h1>
      </v-row>
    </div>
  </v-container>
</template>

<script>
export default {
  name: "Dashboard",
  components: {},
  data() {
    return {    };
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
h1 {
  color: $primary_color;
}
</style> 