<template>
  <v-row>
    <v-col cols="12" md="12" align="right">
      <legend>Cotação</legend>
      {{ operation.currencyQuotation || 0 }}
    </v-col>
    <v-col cols="12" md="12" align="right">
      <legend>IOF(%)</legend>
      {{ operation.iof || 0 }}
    </v-col>
    <v-col cols="12" md="12" align="right">
      <legend>IOF</legend>
      {{ operation.iofValue || 0 }}
    </v-col>
    <v-col cols="12" md="12" align="right">
      <legend>IR(%)</legend>
      {{ operation.ir || 0 }}
    </v-col>
    <v-col cols="12" md="12" align="right">
      <legend>IR</legend>
      {{ operation.irValue || 0 }}
    </v-col>
    <v-col cols="12" md="12" align="right">
      <legend>Custo de Contrato de Câmbio</legend>
      {{ operation.exchangeContractCost || 0 }}
    </v-col> 
    <v-col cols="12" md="12" align="right">
      <legend>Taxa do Cliente</legend>
      {{ operation.customerRate || 0 }}
    </v-col>
    <v-col cols="12" md="12" align="right">
      <legend>VET</legend>
      {{ operation.vet || 0 }}
    </v-col>
    <v-col cols="12" md="12" align="right">
      <legend>Total da Operação</legend>
      {{ operation.totalValue || 0 }}
    </v-col>
  </v-row>
</template>

<script>
// Utils
import operationUtils from "@/utils/operation";

export default {
  name: "OperationPreviewExchange",
  mixins: [  ],
  components: {  },
  data() {
    return {
      operation: this.operationProp,
    };
  },
  model: {
    prop: "operationProp",
    event: "onChange",
  },
  props: {
    operationProp: {
      type: Object,
    },
  },
  watch: {
    operation() {
      this.$emit("onChange", this.operation);
    },
    "operation.nature"() {
      this.updateValues();
    },
    "operation.amount"() {
      this.updateValues();
    },
    "operation.currency"() {
      this.operation.currencyQuotation = 4.7789
      operationUtils.resetSpreads(this.operation);
      this.updateValues();
    },
    "operation.exchangeBuy"() {
      this.updateValues();
    },
    "operation.bank"() {
      this.updateValues();
    },
    "operation.spread"() {
      this.updateValues();
    },
    "operation.customerRate"() {
      operationUtils.getSpreadFromCustomerRateRemittance(this.operation);
      operationUtils.calculateOperationRemittance(this.operation);
    },
  },
  mounted() {
  },
  computed: {
    
  },
  methods: {
    updateValues(){
      operationUtils.setBaseValuesOperationRemittance(this.operation);
      operationUtils.calculateOperationRemittance(this.operation);
    }


  }
};
</script>

<style></style>
